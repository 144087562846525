/**
 * @param {ContactFormConfiguration} config
 */
export const emitSubmissionSuccessEvent = (config) => {
  if (global.isGoogleAnalyticsEnabled()) {
    global.gtag('event', `contact_form_submission`, {
      contact_form_id: config.contactForm.id,
      contact_form_name: config.contactForm.title,
      site_title: config.site.title,
      site_id: config.site.id,
    });
  }
  (config.googleAnalytics.accounts || []).forEach((account) => {
    if (global.isGoogleAnalyticsEnabled()) {
      global.gtag('config', account, {
        page_title: config.googleAnalytics.specificTracking
          ? config.googleAnalytics.specificTrackingUrl
          : global.location.pathname,
      });
    }
  });

  if (global.isMatomoEnabled()) {
    // eslint-disable-next-line no-underscore-dangle
    global._paq.push([
      'trackEvent',
      'Y360Analytics',
      'contact_form_submission',
      config.contactForm.title,
      config.site.id,
    ]);
  }

  if (config.contactForm.scriptCodeOnSubmit !== null) {
    // eslint-disable-next-line no-eval
    eval(config.contactForm.scriptCodeOnSubmit); // sorry
  }
};

/**
 * @param {ContactFormConfiguration} config
 */
export const emitSubmissionValidationFailureEvent = (config) => {
  if (global.isGoogleAnalyticsEnabled()) {
    global.gtag('event', 'contact_form_submission_validation_error', {
      contact_form_id: config.contactForm.id,
      contact_form_name: config.contactForm.title,
      site_title: config.site.title,
      site_id: config.site.id,
    });
  }

  if (global.isMatomoEnabled()) {
    // eslint-disable-next-line no-underscore-dangle
    global._paq.push([
      'trackEvent',
      'Y360Analytics',
      'contact_form_submission_validation_error',
      config.contactForm.title,
      config.site.id,
    ]);
  }
};
