import { formFieldSelector } from './selectors';
import { showFieldAlert } from './alerts';
import { getFieldAlertById } from './finders';

/**
 * @param {HTMLElement} $cf
 * @param {Function} cb
 */
export const forEachFields = ($cf, cb) => {
  const $fields = $cf.querySelectorAll(formFieldSelector);

  for (let i = 0, len = $fields.length; i < len; i += 1) {
    cb($fields[i]);
  }
};

/**
 * @param {HTMLLabelElement} $label
 * @return {String}
 */
export const extractFieldLabel = ($label) => $label.lastChild.textContent.trim(); // `.lastChild`, in order to skip `*` (when field is required) text.

/**
 * @param {HTMLElement} $cf
 * @param {Array.<{ property: Number, message: String, label: String }>} errors
 */
export const displayFieldsErrors = ($cf, errors) => {
  errors.forEach((error) => {
    const { message, property: fieldId } = error;
    const $alert = getFieldAlertById($cf, fieldId);

    if (!$alert) {
      global.alert(message);
      return;
    }

    showFieldAlert($alert, message);
  });
};
