import { loaderSelector } from './selectors';

/**
 * @param {HTMLElement} $cf
 */
export const showLoader = ($cf) => {
  const $loader = $cf.querySelector(loaderSelector);
  $loader.classList.remove('d-none');
};

/**
 * @param {HTMLElement} $cf
 */
export const hideLoader = ($cf) => {
  const $loader = $cf.querySelector(loaderSelector);
  $loader.classList.add('d-none');
};
