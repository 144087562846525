import { fieldAlertSelector, fieldLabelSelector, formGroupSelector } from './selectors';

/**
 * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} $field
 * @return {HTMLElement}
 */
export const getFieldAlertEl = ($field) => {
  const $formGroup = $field.closest(formGroupSelector);
  return $formGroup.querySelector(fieldAlertSelector);
};

export const getFieldAlertById = ($cf, fieldId) => $cf.querySelector(`[data-form-alert-id="${fieldId}"]`);

/**
 * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} $field
 * @return {HTMLLabelElement}
 */
export const getFieldLabelEl = ($field) => {
  const $formGroup = $field.closest(formGroupSelector);
  return $formGroup.querySelector(fieldLabelSelector);
};
