import { formGroupSelector } from './selectors';
/**
 * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} $field
 * @return {Boolean}
 */
export const isFieldForRgpd = ($field) => {
  const $formGroup = $field.closest(formGroupSelector);

  return $formGroup.classList.contains('yp-form__group--rgpd');
};

/**
 * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} $field
 * @return {Boolean}
 */
export const isFieldRequired = ($field) => {
  if (isFieldForRgpd($field)) {
    return true;
  }

  return $field.hasAttribute('required');
};

/**
 * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} $field
 * @return {Boolean}
 */
export const isFieldEmpty = ($field) => ($field.type === 'checkbox' ? $field.checked === false : $field.value === '');
