/**
 * @param {HTMLElement} $fieldAlert
 * @param {String} message
 */
export const showFieldAlert = ($fieldAlert, message) => {
  // eslint-disable-next-line no-param-reassign
  $fieldAlert.textContent = message;
};

/**
 * @param {HTMLElement} $fieldAlert
 */
export const hideFieldAlert = ($fieldAlert) => {
  // eslint-disable-next-line no-param-reassign
  $fieldAlert.textContent = '';
};
