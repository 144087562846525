/**
 * Algorithme
 * ==========
 *
 * 0. Cliquer sur le bouton "Envoyer le message"
 * 1. Vider les erreurs
 * 2. Check les champs requis
 *   2.1. Si RGPD, faire un traitement spécifique
 *   2.2. Sinon, afficher une alerte (sous le champ), disant que le champ est requis
 * 3. Call le Google Captcha
 * 4. Ré-écrire la méthode globale `YproxRecaptchas[contentId].callback` (templates/theme/plymouth/_captcha.html.twig:14)
 * 5. Afficher le loading (+ désactivation bouton submit)
 * 6. Soumettre le formulaire en AJAX au bon endpoint
 * 7. Cacher le loading (+ réactiver bouton submit)
 * 8. Si succès :
 *   8.1. Envoyer l'event à Google Analytics (+ gérer le cas du analytics spécifique)
 *   8.2. Afficher le lien de téléchargement de fichier (si besoin)
 *   8.3. Ouvrir automatiquement le fichier joint dans une nouvelle fenêtre (si besoin)
 *   8.4. Si page de redirection :
 *     8.4.1. Alors rediriger vers la page
 *     8.4.2. Sinon afficher le message de confirmation
 * 9. Si erreur :
 *   9.1. Afficher les erreurs pour chaque champ (+ envoyer les erreurs aux analytics spécifique)
 *   9.2. Reset le google captcha
 */

import 'element-closest';
import { ContactForm } from './contact-form';

global.YproxContactForm = ContactForm;

document.addEventListener(
  'DOMContentLoaded',
  () => {
    document.querySelectorAll('.yp-form--contact').forEach(($contactForm) => new ContactForm($contactForm));
  },
  false
);
