/**
 * @typedef {Object} ContactFormConfiguration
 * @property {Object} contactForm
 * @property {Number} contactForm.id
 * @property {String} contactForm.title
 * @property {String|null} contactForm.scriptCodeOnSubmit
 * @property {String} contactForm.confirmationMessage
 * @property {Object} contactForm.attachment
 * @property {String|null} contactForm.redirectionPage
 * @property {String|null} contactForm.attachment.fullpathFilename
 * @property {String} contactForm.attachment.downloadSentence
 * @property {String} contactForm.attachment.downloadLabel
 * @property {Object} messages
 * @property {String} messages.errorFieldIsRequired
 * @property {String} messages.errorPrivacyAgreement
 * @property {Object} googleCaptcha
 * @property {Boolean} googleCaptcha.displayAtBoot
 * @property {Boolean} googleCaptcha.display
 * @property {Object} googleAnalytics
 * @property {String[]} googleAnalytics.accounts
 * @property {Boolean} googleAnalytics.specificTracking
 * @property {String} googleAnalytics.specificTrackingUrl
 * @property {String} googleAnalytics.specificTrackingErrorsCategory
 * @property {String} googleAnalytics.specificTrackingErrorsLabel
 * @property {Object} site
 * @property {Number} site.id
 * @property {String} site.title
 * @property {Object} content
 * @property {String} content.id
 */

import axios from 'axios';

import { componentSelector } from './selectors';
import { displayFieldsErrors, extractFieldLabel, forEachFields } from './utils';
import { isFieldEmpty, isFieldForRgpd, isFieldRequired } from './checkers';
import { getFieldAlertEl, getFieldLabelEl } from './finders';
import { hideFieldAlert, showFieldAlert } from './alerts';
import { hideLoader, showLoader } from './loaders';
import { emitSubmissionSuccessEvent, emitSubmissionValidationFailureEvent } from './analytics';

export class ContactForm {
  /**
   * @param {HTMLElement} $contactForm
   */
  constructor($contactForm) {
    this.$contactForm = $contactForm;
    this.config = this.getConfig();

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Config contact form', this.config);
    }

    this.initGoogleAutocompletes();

    if (this.config.googleCaptcha.displayAtBoot) {
      this.initGoogleRecaptchas();
      this.bindGoogleRecaptchaCallback();
    }

    this.bindFormSubmit();
  }

  /**
   * @private
   * @return {ContactFormConfiguration}
   */
  getConfig() {
    return JSON.parse(this.$contactForm.dataset.configuration);
  }

  /**
   * @private
   */
  bindFormSubmit() {
    let hasErrors = false;

    this.$contactForm.addEventListener(
      'submit',
      (e) => {
        e.preventDefault();

        // Clear previous validation errors
        hasErrors = false;
        this.forEachFields(($field) => {
          hideFieldAlert(getFieldAlertEl($field));
          $field.classList.remove('is-invalid');
        });

        // Check required fields
        this.forEachFields(($field) => {
          if (isFieldRequired($field) && isFieldEmpty($field)) {
            hasErrors = true;

            const $fieldAlert = getFieldAlertEl($field);
            $field.classList.add('is-invalid');

            if (isFieldForRgpd($field)) {
              showFieldAlert($fieldAlert, this.config.messages.errorPrivacyAgreement);
            } else {
              const $fieldLabel = getFieldLabelEl($field);
              const label = extractFieldLabel($fieldLabel);

              showFieldAlert($fieldAlert, `${label} ${this.config.messages.errorFieldIsRequired}`);
            }
          }
        });

        if (hasErrors) {
          if (this.config.googleCaptcha.display) {
            this.resetGoogleRecaptcha();
          }

          return;
        }

        if (this.config.googleCaptcha.display) {
          this.bindGoogleRecaptchaCallback();
          this.executeGoogleRecaptcha();
        } else {
          this.submitForm(null);
        }
      },
      false
    );
  }

  /**
   * @private
   */
  submitForm() {
    const $form = this.$contactForm.querySelector(componentSelector);

    this.showLoader();

    /**
     * @param {AxiosResponse} response
     */
    const onSuccess = (response) => {
      const { confirmationMessage, spamMessage, attachment, redirectionPage } = this.config.contactForm;

      if (response.data.spam !== true && response.data.ignored !== true) {
        emitSubmissionSuccessEvent(this.config);
      }

      if (redirectionPage !== null) {
        window.location.href = redirectionPage;
        return;
      }

      $form.innerHTML = `
        <div>
          <p>${confirmationMessage}</p>

          ${
            response.data.spam !== true
              ? ''
              : `
                <p class="yp-form__spam">${spamMessage}</p>
              `
          }

          ${
            attachment.fullpathFilename === null
              ? ''
              : `
            <p>
              ${attachment.downloadSentence}
              <a href="${attachment.fullpathFilename}" rel="noopener norefferer">
                ${attachment.downloadLabel}
              </a>
            </p>`
          }
        </div>
      `;
    };

    /**
     * @param {AxiosResponse} response
     */
    const onError = (response) => {
      const { data } = response;

      emitSubmissionValidationFailureEvent(this.config);

      if (data.error) {
        global.alert(data.error);
      }

      if (Array.isArray(data.errors)) {
        this.displayFieldsErrors(data.errors);
      }

      if (this.config.googleCaptcha.display) {
        this.resetGoogleRecaptcha();
      }
    };

    /**
     * @param {AxiosError} error
     */
    const onFatalError = (error) => {
      // TODO: Attendre le passage des intés pour rajouter une zone où on afficherait l'erreur globale
      // eslint-disable-next-line no-console
      console.error(error);
    };

    axios
      .post($form.getAttribute('action'), global.serializeForm($form))
      .then((response) => {
        this.hideLoader();

        if (response.data.success === true) {
          onSuccess(response);
        } else {
          onError(response);
        }
      })
      .catch((error) => {
        this.hideLoader();
        onFatalError(error);
      });
  }

  /**
   * @private
   */
  showLoader() {
    showLoader(this.$contactForm);
  }

  /**
   * @private
   */
  hideLoader() {
    hideLoader(this.$contactForm);
  }

  /**
   * @private
   * @param {Function} callback
   */
  forEachFields(callback) {
    forEachFields(this.$contactForm, callback);
  }

  /**
   * @private
   * @param {Array.<{ property: Number, message: String, label: String }>} errors
   */
  displayFieldsErrors(errors) {
    displayFieldsErrors(this.$contactForm, errors);
  }

  /**
   * @private
   */
  initGoogleAutocompletes() {
    const inputs = this.$contactForm.querySelectorAll('.yp-form__autocomplete');
    inputs.forEach((input) => {
      const inputElement = document.getElementById(input.id);
      // eslint-disable-next-line no-new
      new google.maps.places.Autocomplete(inputElement, {
        componentRestrictions: {
          country: ['fr'],
        },
        fields: ['name'],
        types: ['address'],
      });
    });
  }

  /**
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  initGoogleRecaptchas() {
    global.initGoogleRecaptchas();
  }

  /**
   * @private
   */
  resetGoogleRecaptcha() {
    global.resetGoogleRecaptcha(this.config.content.id);
  }

  /**
   * @private
   */
  bindGoogleRecaptchaCallback() {
    global.bindGoogleRecaptchaCallback(this.config.content.id, this.submitForm.bind(this));
  }

  /**
   * @private
   */
  executeGoogleRecaptcha() {
    global.executeGoogleRecaptcha(this.config.content.id);
  }
}
